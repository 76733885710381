import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"

import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"

import lowRedCell from "../../images/4.5.1_lowredbloodcell@4x.jpg"
import lowPlatelet from "../../images/4.5.2_lowplatelet@4x.png"
import lowNeutrophil from "../../images/4.5.3_lowneutrophil@4x.png";

import washHands from "../../images/4.5_washhands.jpg";
import fever from "../../images/4.5_fever.jpg";
import wearMask from "../../images/4.5_wearamask.jpg";
import stayAway from "../../images/4.5_stayawayfrompeople.jpg";
import shower from "../../images/4.5_shower.jpg";

const BloodCountsPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Blood Counts" description="Neuroblastoma treatment can impact the number of cells in your child’s blood. Learn more about neuroblastoma blood counts and how your child’s healthcare team can measure and address these changes." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Blood Counts">
        <h1 className="section__heading section__heading--green h1">Blood Counts</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>Your child’s neuroblastoma treatment can impact the number of cells in their blood, so it is important for the healthcare team to monitor their blood counts throughout treatment.</p>
                <p>To do this, your child’s healthcare team uses a laboratory test called a <Link rel="glossary" to="/resources/glossary/#complete-blood-count-cbc">complete blood count</Link> (or CBC) to measure the number of blood cells in your child’s body. During neuroblastoma treatment, they will focus on the following aspects of your child’s CBC: </p>
                <ul className="ul--dotted">
                  <li><Link rel="glossary" to="/resources/glossary/#absolute-neutrophil-count">Absolute neutrophil count</Link>—measures how many <Link rel="glossary" to="/resources/glossary/#neutrophils">neutrophils</Link> are present</li>
                  <li><Link rel="glossary" to="/resources/glossary/#hemoglobin-and-or-hematocrit">Hemoglobin and/or hematocrit</Link>—measures how many red blood cells are present</li>
                  <li><Link rel="glossary" to="/resources/glossary/#platelet-count">Platelet count</Link>—measures how many <Link rel="glossary" to="/resources/glossary/#platelets">platelets</Link> are present</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor:SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h2 className="tc-barney jost-med h2">Potential causes of low blood counts</h2>
            <p>Certain neuroblastoma treatments, such as <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link>, radiation, and <Link rel="glossary" to="/resources/glossary/#antibody-therapy">antibody therapy</Link>, can affect the bone marrow’s ability to make blood cells. It is common for a child with cancer to have low blood counts after receiving treatment. With chemotherapy, neutrophil counts are usually at their lowest 7 to 14 days after the therapy is given. </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-justify align-middle">
          <div className="columns large-8">
            <h3 className="tc-barney jost-med h2">Low red blood cell count (anemia)</h3>
            <p><Link rel="glossary" to="/resources/glossary/#red-blood-cells">Red blood cells</Link> help carry oxygen in the body. Your child’s healthcare team will measure the red blood cells by checking the <Link rel="glossary" to="/resources/glossary/#hemoglobin-and-or-hematocrit">hemoglobin</Link> (Hgb) and/or <Link rel="glossary" to="/resources/glossary/#hemoglobin-and-or-hematocrit">hematocrit</Link> (Hct) as part of the <Link rel="glossary" to="/resources/glossary/#complete-blood-count-cbc">CBC</Link>. When your child’s hemoglobin and hematocrit are low, it is called <Link rel="glossary" to="/resources/glossary/#anemia">anemia</Link>. </p>
          </div>
          <div className="small-12 large-3 text-center large-text-right mt-4">
            <img src={lowRedCell} style={{maxWidth:`200px`}} alt="If your child is anemic, their neuroblastoma treatment team will administer a red blood cell transfusion through a central line"/>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 mt-2">
          <div className="columns">
            <h4 className="tc-viridian jost-med h4">Signs and symptoms of anemia</h4>
            <p>If your child is anemic, they may develop the following symptoms: </p>
            <div className="row">
              <div className="columns">
                <ul className="ul--dotted">
                  <li>Pale skin</li>
                  <li>Pale gums</li>
                  <li>Feeling tired</li>
                  <li>Dizziness</li>
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--dotted">
                  <li>Sleeping more and playing less</li>
                  <li>Headache</li>
                  <li>Fast heart rate</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <h4 className="tc-viridian jost-med h4">Treating anemia</h4>
            <p>If your child’s red blood cell count is too low, your child’s healthcare team may order a <Link rel="glossary" to="/resources/glossary/#blood-transfusion">blood transfusion</Link>. During a red blood cell transfusion (also called packed red blood cells or PRBC transfusion), red blood cells will be given through your child’s <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link>.  </p>
            <p>The transfusion is usually given over 2 to 4 hours. Your child will be closely monitored during the transfusion. It is very common to receive blood transfusions during cancer treatment.  </p>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2 mt-2 mb-2" text="Please call your child’s healthcare team if your child is dizzy or light-headed, or if you are concerned and think they may need a blood transfusion."/>
      </Section>

      <Section style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner align-justify align-middle small-col-reverse">
          <div className="small-12 large-3 text-center large-text-right mt-4">
            <img src={lowPlatelet} style={{maxWidth:`200px`}} alt="Your child’s neuroblastoma treatment team may give them a platelet transfusion if your child’s platelet count is too low"/>
          </div>
          <div className="columns large-8">
            <h3 className="tc-barney jost-med h2">Low platelet count (thrombocytopenia) </h3>
            <p><Link rel="glossary" to="/resources/glossary/#platelets">Platelets</Link> help stop bleeding by forming clots. Your child’s healthcare team will check the <Link rel="glossary" to="/resources/glossary/#platelet-count">platelet count</Link> as part of the <Link rel="glossary" to="/resources/glossary/#complete-blood-count-cbc">CBC</Link>. When your child’s platelet count is low, it is called <Link rel="glossary" to="/resources/glossary/#thrombocytopenia">thrombocytopenia</Link>.  </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <h4 className="tc-viridian jost-med h4">Signs and symptoms of thrombocytopenia</h4>
            <p>If your child is suffering from low platelets (thrombocytopenia), they may develop the following symptoms: </p>
            <div className="row">
              <div className="columns">
                <ul className="ul--dotted">
                  <li>Bruising</li>
                  <li>Tiny red spots on the skin (also called <Link rel="glossary" to="/resources/glossary/#petechiae">petechiae</Link>)</li>
                  <li>Nosebleeds</li>
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--dotted">
                  <li>Bleeding gums</li>
                  <li>Bleeding from the <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link></li>
                  <li>Blood in the urine, stools, or vomit</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <h4 className="tc-viridian jost-med h4">Treating thrombocytopenia</h4>
            <p>If your child's platelet count is too low, their healthcare team may order a platelet transfusion. During a transfusion, platelets will be given through your child’s central line. The transfusion is usually given over 1 to 2 hours. Your child will be closely monitored during the transfusion. It is very common to receive platelet transfusions during cancer treatment. </p>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2 mt-2 mb-2" text="Please call your child’s healthcare team if your child has unusual bruising or if their bleeding won’t stop. "/>
      </Section>

      <Section>
        <div className="row row--inner align-justify align-middle">
          <div className="columns large-8 lg_mt-4 mt-4">
            <h3 className="tc-barney jost-med h2">Low neutrophils (neutropenia)</h3>
            <p><Link rel="glossary" to="/resources/glossary/#neutrophils">Neutrophils</Link> are <Link rel="glossary" to="/resources/glossary/#white-blood-cell">white blood cells</Link> that play an important role in defending your child’s body from infection. Your child’s healthcare team will check the <Link rel="glossary" to="/resources/glossary/#absolute-neutrophil-count">absolute neutrophil count</Link> as part of the <Link rel="glossary" to="/resources/glossary/#complete-blood-count-cbc">CBC</Link>. When the neutrophil count is low (less than 500), it is referred to as <Link rel="glossary" to="/resources/glossary/#neutropenia">neutropenia</Link>.</p>
          </div>
          <div className="small-12 large-3 text-center large-text-right mt-4">
            <img src={lowNeutrophil} style={{maxWidth:`200px`}} alt="If your child’s neutrophils (white blood cells) are low, their neuroblastoma care team may delay certain treatments"/>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 mt-2">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Signs and symptoms of neutropenia</h4>
            <p>Often, there are no outward signs and symptoms of neutropenia. However, if your child is neutropenic, they may be more likely to get infections. When the body cannot fight an infection very well, a child may quickly become seriously ill. Often, a <Link rel="glossary" to="/resources/glossary/#fever">fever</Link> is the only sign of infection in a child with neutropenia. For this reason, a fever is considered a medical emergency. </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Treating neutropenia</h4>
            <p>Some patients need medicines to help build up neutrophils that have been destroyed by strong neuroblastoma treatments. These medicines may be given in the hospital, clinic, or home. Your healthcare team will let you know if your child needs one of these medicines.</p>
            <p>If your child is neutropenic, your child’s healthcare team may delay certain treatments (<Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link>) until the neutrophil count rises.</p>
          </div>
        </div>
        <InlineInfocard className="lg_mt-2 mt-2 mb-2 lg_mb-2" text="If your child becomes ill or has a fever, they may need to be admitted to the hospital for antibiotics and to be closely monitored by the healthcare team. Do not give your child medication to treat the fever unless you are told to do so by their healthcare team."/>
        <div className="row row--inner align-middle lg_mt-2">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Managing neutropenia at home</h4>
            <p>Keeping your child healthy and safe is key. Not all infections can be prevented when your child has a low neutrophil count, but good hygiene practices can help avoid some. Some precautions you can take to help protect your child from the common causes of infection include:</p>
          </div>
        </div>
        <div className="row row--inner lg_mt-4">
          <div className="columns">
            <Slider className="grid-slider grid-slider--large-3" {...settings}>
              <div className="text-center">
                <img src={washHands} alt="" style={{maxWidth:`120px`}}/>
                <p className="mt-2">Washing their hands with soap and water frequently (as should anyone around your child)</p>
              </div>
              <div className="text-center">
                <img src={shower} alt="" style={{maxWidth:`100px`}}/>
                <p className="mt-2">Taking a shower or bath on a daily basis</p>
              </div>
              <div className="text-center">
                <img src={stayAway} alt="" style={{maxWidth:`190px`}}/>
                <p className="mt-2">Avoiding contact with people who may be sick</p>
              </div>
              <div className="text-center">
                <img src={wearMask} alt="" style={{maxWidth:`100px`}}/>
                <p className="mt-2">Wearing a mask when in public</p>
              </div>
              <div className="text-center">
                <img className="lg_mt-2" src={fever} alt="" style={{maxWidth:`90px`}}/>
                <p className="mt-2 lg_mt-4">Taking your child’s temperature if they feel warm or do not feel or look well</p>
              </div>
            </Slider>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-4 lg_mb-2 mt-4 mb-2" text="Please call your healthcare team immediately if your child has a fever or chills, or you are concerned they may have an infection."/>
        <SupportiveCareFooterLinks/>
      </Section>


      <InteriorPageFooter>

      <UpNextCard topic="Hair Loss" title="Coping with hair loss" lead="Learn why hair loss happens and how to help your child cope with their change in appearance." url="/supportive-care/hair-loss/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default BloodCountsPage
